import React, { useEffect, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import GospelButton from "../../common/GospelButton/GospelButton";
import {
  Cancel,
  KeyboardArrowDown,
  PlayCircleOutline,
  RemoveRedEyeSharp,
  YouTube,
} from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import { get } from "../../utility/api";
import { CircularProgress } from "@mui/material";
import { SearchBrandData, SearchChannelData } from "../../types/search";
import { ConfigProvider, Modal } from "antd";
import ReactPlayer from "react-player";

function Search() {
  const location = useLocation(); // Get the current location
  const searchParams = new URLSearchParams(location.search); // Create URLSearchParams object
  const searchQ = searchParams.get("q") ?? ""; // Get the 'q' query parameter
  const searchC = searchParams.get("c") ?? "Channels"; // Get the 'c' query parameter, default to 'channels'
  const [selectedVideoId, setSelectedVideoId] = useState<string | null>(null);
  const [isYoutubeOpen, setIsYoutubeOpen] = useState<boolean>(false);

  const [placeholder, setPlaceholder] = useState<string>(
    "Name, username, channels, etc."
  );
  const navigate = useNavigate();
  const [channelData, setChannelData] = useState<SearchChannelData[]>([]);
  const [brandData, setBrandData] = useState<SearchBrandData[]>([]);
  const [searchType, setSearchType] = useState<string>(searchC);
  const [searchKey, setSearchKey] = useState<string>(searchQ);
  const [showChannelInfo, setShowChannelInfo] = useState<boolean>(false);
  const [channelLoading, setChannelLoading] = useState<boolean>(false);
  const [brandLoading, setBrandLoading] = useState<boolean>(false);
  const [hasSearched, setHasSearched] = useState<boolean>(false);


  const toggleShowChannelInfo = () => {
    setShowChannelInfo(!showChannelInfo);
  };

  const openModal = (videoId: string) => {
    setSelectedVideoId(videoId);
    setIsYoutubeOpen(true);
  };

  const closeModal = () => {
    setIsYoutubeOpen(false);
    setSelectedVideoId(null);
  };

  const handleSelectChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    const value = event.target.value;
    setPlaceholder(`Name, username, ${value.toLowerCase()}, etc.`);
    setSearchType(value);
    setHasSearched(false);
  };

  const options = ["Channels", "Brands"].map((item) => ({
    value: item.toLowerCase(), // Use lowercase for consistent handling
    label: item,
  }));

  const handleSearch = () => {
    if (!searchKey.trim()) return;
    setHasSearched(true);

    const queryParams = new URLSearchParams({
      q: searchKey,
      c: searchType.toLowerCase(),
    }).toString();

    navigate(`/search/?${queryParams}`);
  };

  useEffect(() => {
    const query = searchQ;
    const type = searchC;
  
    setSearchKey(query);
    setSearchType(type);
    setPlaceholder(`Name, username, ${type.toLowerCase()}, etc.`);
  
    if (query) {
     
      if (type === "channels") {
        setChannelLoading(true);
        get<SearchChannelData[]>(
          `/youtube/channel/search?cat=channel&search_term=${query}`
        )
          .then((res) => {
            setChannelData(res ?? []);
            setBrandData([]);
          })
          .catch(console.error)
          .finally(() => setChannelLoading(false));
      } else {
        setBrandLoading(true);
        get<SearchBrandData[]>(`/gospel/brand/search?search_term=${query}`)
          .then((res) => {
            setBrandData(res ?? []);
            setChannelData([]);
          })
          .catch(console.error)
          .finally(() => setBrandLoading(false));
      }
    }
  }, [location.search]); // Only trigger when user actually searches
  

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="flex items-center justify-center mt-[70px] md:mt-[70px]">
        {/* Adjust margin-top to match the header height */}
        <div className="text-center py-4 mx-auto overflow-y-auto mt-5 w-4/5">
          {/* max-w-screen-md sets maximum width for content, overflow-y-auto enables scrolling */}
          <h1 className="text-2xl font-semibold md:text-2xl lg:text-2xl mb-2 text-gospel-gray">
            Search
          </h1>
          <div className="text-center py-8 px-4 gap-2  w-full bg-white rounded-md shadow-md mt-8 justify-center">
            {/* Input Field */}
            <div className="flex items-center w-full justify-center flex-col md:flex-row xl:gap-0 lg:gap-0 md:gap-0 gap-2">
              <input
                type="text"
                className="lg:rounded-r-none xl:rounded-r-none md:rounded-r-none px-2 py-2.5 placeholder-gospel-gray-300 placeholder-opacity-100 border border-gray-300 focus:border-gospel-gray-200 hover:border-gospel-gray-200 focus:outline-none lg:rounded-l-md xl:rounded-l-md md:rounded-l-md rounded-md text-gospel-gray-300 text-sm md:w-2/5 lg:w-2/5 xl:w-2/5 w-full"
                placeholder={placeholder}
                value={searchKey}
                onChange={(e) => {
                  setSearchKey(e.target.value);
                  setHasSearched(false); // Optional reset
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
              />
              <div
                className="relative md:w-[11%] lg:w-[11%] xl:w-[11%] w-full"
                style={{ width: "11%" }}
              >
                <select
                  value={searchType}
                  onChange={handleSelectChange}
                  className="text-sm text-gospel-purple lg:border-l-0 xl:border-l-0 md:border-l-0 px-4 py-2.5 border border-gray-300 focus:border-gospel-gray-200 hover:border-gospel-gray-200 lg:rounded-l-none xl:rounded-l-none rounded-md md:rounded-l-none appearance-none w-full"
                >
                  <option value="" disabled>
                    {"Select"}
                  </option>
                  {options.map((option) => (
                    <option key={option.value} value={option.value}>
                      {option.label}
                    </option>
                  ))}
                </select>
                <div className="absolute inset-y-0 right-1 flex items-center pointer-events-none">
                  <KeyboardArrowDown className="text-black" />
                </div>
              </div>
              {/* Search Button */}
              <GospelButton
                className="text-white h-[40px] w-[90px] rounded-md text-sm font-medium transition duration-500 cursor-pointer  bg-gospel-purple-200 bg-gradient-to-t from-transparent to-gospel-purple  hover:bg-gospel-purple ml-3"
                label="Search"
                onClick={() => handleSearch()}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-center">
        {searchType === "channels" && (
          <>
            {channelLoading ? (
              <div className="p-8 flex items-center justify-center">
                <CircularProgress className="text-gospel-gray-300" size={30} />
              </div>
            ) : hasSearched && channelData.length === 0 ? (
              <div className="text-gospel-purple font-semibold">
                No channels found. Please try another search.
              </div>
            ) : (
              channelData &&
              channelData.length > 0 && (
                <div className="w-4/5">
                  {/* Table Body */}
                  {channelData
                    .slice(0, showChannelInfo ? channelData.length : 10)
                    .map((item) => (
                      <div
                        key={item.channelId}
                        className=" bg-white shadow-md rounded-lg mb-4 border border-gray-200"
                      >
                        <div className="flex flex-col">
                          <div className=" text-left px-2 pt-4 flex flex-row justify-between">
                            {/* Channel Image */}
                            <div className="px-4 ">
                              <a
                                href="/"
                                className="mr-2 text-gospel-purple no-underline"
                              >
                                <img
                                  src={item.channelImage}
                                  alt="channel-logo"
                                  className="w-40 h-40 rounded-full cursor-pointer"
                                />
                              </a>
                            </div>
                            <div className="flex flex-col gap-2 w-4/5">
                              {/* Title */}
                              <div className=" text-gospel-purple text-2xl cursor-pointer">
                                <a href={`/channel/${item.pageSlug}`}>
                                  {item.channelTitle}
                                </a>
                              </div>

                              <div className=" text-gospel-gray-300 text-sm font-normal w-4/5">
                                {item.description}
                              </div>

                              <div className="items-center text-gospel-gray-300 text-base">
                                <span
                                  className={`inline-block rounded-md bg-gray-200 text-gray-700 text-xs transition duration-500 ease-in-out cursor-pointer pr-4 mr-2 mb-2`}
                                >
                                  <span className="youtube inline-block text-white bg-red-600 rounded-l-md px-2 py-1 mr-3">
                                    <YouTube className="mr-1" />{" "}
                                    {/* MUI YouTube Icon */}
                                  </span>
                                  {item.youtube}
                                </span>
                              </div>
                            </div>
                            <div>
                              <button
                                className="flex flex-row p-1 items-center gap-2 bg-gospel-gray-100 hover:bg-gospel-gray-300 rounded-full"
                                onClick={() => {}}
                              >
                                <RemoveRedEyeSharp
                                  className="text-white"
                                  fontSize="small"
                                />
                              </button>
                            </div>
                          </div>
                          <div className="rounded-md border border-gray-200 mx-4 mb-4 px-4 py-2 flex flex-col justify-between">
                            <span className=" text-gospel-gray-400 text-sm mb-2">
                              Last Upload
                            </span>
                            <div>
                              <div className="flex flex-row">
                                <div className="flex w-2/4">
                                  <div
                                    className="relative w-28 h-14 mr-4 bg-black"
                                    onClick={() =>
                                      openModal(item.latestVideo?.videoId)
                                    }
                                  >
                                    <PlayCircleOutline
                                      className="text-white absolute left-1/2 top-1/2 transform -translate-x-1/2 -translate-y-1/2"
                                      style={{ fontSize: "30px" }}
                                    />
                                    <img
                                      src={item.latestVideo?.thumbnailURL}
                                      alt="Video Thumbnail"
                                      className="absolute top-0 left-0 w-full h-full object-cover opacity-60"
                                    />
                                  </div>
                                  <div>
                                    <div className="text-sm font-medium">
                                      {item.latestVideo?.title}
                                    </div>
                                    <div className="text-gospel-gray-400 text-xs">
                                      {item.latestVideo?.viewCount?.toLocaleString()}{" "}
                                      views | Uploaded{" "}
                                      {item.latestVideo?.uploadDate}
                                    </div>
                                  </div>
                                </div>
                                <div className="w-1/6 text-center">
                                  <div className="text-sm text-gospel-gray-400 mb-1">
                                    All-Time Views
                                  </div>
                                  <div className="font-medium text-sm">
                                    {item.allTimeViews?.toLocaleString()}
                                  </div>
                                </div>
                                <div className="w-1/6 text-center">
                                  <div className="text-sm text-gospel-gray-400 mb-1">
                                    All-Time Subs
                                  </div>
                                  <div className="font-medium text-sm">
                                    {item?.allTimeSubs}
                                  </div>
                                </div>
                                <div className="w-1/6 text-center">
                                  <div className="text-sm text-gospel-gray-400 mb-1">
                                    30 Day Views
                                  </div>
                                  <div className="font-medium text-sm">
                                    {item.thirtyDayViews?.toLocaleString()}
                                  </div>
                                </div>
                                <div className="w-1/6 text-center">
                                  <div className="text-sm text-gospel-gray-400 mb-1">
                                    30 Day Subs
                                  </div>
                                  <div className="font-medium text-sm">
                                    {item?.thirtyDaySubs}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {channelData && channelData.length > 10 && (
                    <div className=" flex items-center justify-center my-4 ">
                      <GospelButton
                        className="bg-white p-2 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                        label={showChannelInfo ? "Show Less" : "View More"}
                        onClick={toggleShowChannelInfo}
                      />
                    </div>
                  )}
                </div>
              )
            )}
          </>
        )}

        {searchType === "brands" && (
          <>
            {brandLoading ? (
              <div className="p-8 flex items-center justify-center">
                <CircularProgress className="text-gospel-gray-300" size={30} />
              </div>
            ) : hasSearched && brandData.length === 0 ? (
              <div className="text-gospel-purple font-semibold">
                No brands found. Please try another search.
              </div>
            ) : (
              brandData &&
              brandData.length > 0 && (
                <div className="w-4/5">
                  {/* Table Body */}
                  {brandData
                    .slice(0, showChannelInfo ? brandData.length : 10)
                    .map((item) => (
                      <div
                        key={item.id}
                        className=" bg-white shadow-md rounded-lg mb-4 border border-gray-200"
                      >
                        <div className="flex flex-col">
                          <div className=" text-left px-2 pt-4 flex flex-row">
                            {/* Channel Image */}
                            <div className="px-4 ">
                              <a
                                href="/"
                                className="mr-2 text-gospel-purple no-underline"
                              >
                                <img
                                  src={item.image}
                                  alt="channel-logo"
                                  className="w-40 h-40 rounded-full cursor-pointer"
                                />
                              </a>
                            </div>
                            <div className="flex flex-col gap-2 w-4/5">
                              {/* Title */}
                              <div className=" text-gospel-purple text-2xl cursor-pointer">
                                <a href={`/brand/${item.pageSlug}`}>
                                  {item.name}
                                </a>
                              </div>

                              <div className=" text-gospel-gray-300 text-sm font-normal w-4/5">
                                {`${item.ninetyDayVideoCount} videos in the last 90 days.`}
                              </div>
                              <div className=" flex-row">
                                <span className="font-semibold text-sm">
                                  {" "}
                                  Category:{" "}
                                </span>
                                <span className="font-normal text-gospel-purple cursor-pointer text-sm">
                                  {" "}
                                  {item.brandCategoryName}{" "}
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  {brandData && brandData.length > 10 && (
                    <div className=" flex items-center justify-center my-4 ">
                      <GospelButton
                        className="bg-white p-2 w-36 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                        label={showChannelInfo ? "Show Less" : "View More"}
                        onClick={toggleShowChannelInfo}
                      />
                    </div>
                  )}
                </div>
              )
            )}
          </>
        )}
      </div>
      {selectedVideoId && (
        <ConfigProvider
          theme={{
            components: {
              Modal: {
                titleFontSize: 18,
                headerBg: "transparent",
                contentBg: "",
                titleColor: "#353b3d",
                boxShadow: "none",
                fontFamily: "Montserrat, sans-serif",
              },
            },
          }}
        >
          <Modal
            title=""
            open={isYoutubeOpen}
            width={"fit-content"}
            height={"fit-content"}
            footer={null}
            closeIcon={
              <Cancel
                className="absolute bg-gospel-purple text-white rounded-full bottom-0"
                fontSize="large"
                onClick={closeModal}
              />
            }
            className="text-center"
          >
            <ReactPlayer
              controls
              width="750px"
              height={"450px"}
              url={`https://youtube.com/watch?v=${selectedVideoId}`}
            />
          </Modal>
        </ConfigProvider>
      )}
      <Footer />
    </div>
  );
}

export default Search;
