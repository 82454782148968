import React, { useEffect, useRef, useState } from "react";
import Header from "../../common/Header/Header";
import Footer from "../../common/Footer/Footer";
import { get } from "../../utility/api";
import GospelSelectBox from "../../common/GospelSelect/GospelSelect";
import GospelButton from "../../common/GospelButton/GospelButton";
import { useNavigate, useParams } from "react-router-dom";
import {
  BrandChartData,
  BrandInfo,
  BrandStatistics,
  BrandTrendingVideoInfo,
  TrendingVideoKey,
} from "../../types/channel";
import { ConfigProvider, DatePicker, Modal } from "antd";
import { Tabs } from "antd";
import dayjs, { Dayjs } from "dayjs";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import Highcharts, {
  SeriesLineOptions,
  SeriesOptionsType,
  SeriesScatterOptions,
} from "highcharts";
import HighchartsReact from "highcharts-react-official";
import HighchartsMore from "highcharts/highcharts-more";
import { CircularProgress } from "@mui/material";
import { TrendingVideoTable, brandChartTabs } from "./BrandUtils";
import {
  Platform,
  formatLargeNumber,
  formatLargeNumberWithDecimal,
  getPlatform,
  platformIcons,
  quickDateRanges,
} from "../../common/utils";
import ChannelSearch from "./ChannelSearch";
import VideoSearch from "./VideoSearch";
import { formatNumberWithCommas } from "../../utility/common";
import { Clear } from "@mui/icons-material";
import moment from "moment";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore"; // ES 2015

dayjs.extend(isSameOrBefore);
HighchartsMore(Highcharts);
dayjs.extend(utc);
dayjs.extend(timezone);

const { RangePicker } = DatePicker;

declare module "highcharts" {
  interface PointOptionsObject {
    channelName?: string;
    brand?: string;
    date?: string;
    gospelPageUrl?: string;
    thumbnailUrl?: string;
    videoTitle?: string;
    videoUrl?: string;
    views?: number;
    uploadCount?: number;
  }
}

const options = quickDateRanges.map((item) => ({
  value: item.value,
  label: item.label,
}));

const chartLineWidth: number = 4;

const chartInterval: string[] = ["daily", "weekly", "monthly"];

function Brand() {
  const { brandId } = useParams();
  const navigate = useNavigate();
  const userAccess = useSelector(
    (state: RootState) => state.user.user?.userAccess
  );
  const [brandInfo, setBrandInfo] = useState<BrandInfo>();
  const [selectedValue, setSelectedValue] = useState("");
  const [brandStats, setBrandStats] = useState<any>([]);
  const [selectedTabs, setSelectedTabs] = useState<number[]>([
    brandChartTabs[0].key,
  ]);
  const [selectedInterval, setSelectedInterval] = useState<string>(
    chartInterval[0]
  );
  const [loadingChart, setLoadingChart] = useState<boolean>(false);
  const [loadBrandStat, setLoadBrandStat] = useState<boolean>(false);
  const [loadBrandVideos, setLoadBrandVideos] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState({
    startDate: dayjs().subtract(30, "days"),
    endDate: dayjs(),
  });
  const [trendingVideoInfo, setTrendingVideoInfo] = useState<{
    mostRecent: BrandTrendingVideoInfo[];
    mostViewed: BrandTrendingVideoInfo[];
  }>({ mostRecent: [], mostViewed: [] });
  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [showTrendingVideos, setShowTrendingVideos] = useState<boolean>(false);
  const [activeTab, setActiveTab] = useState<TrendingVideoKey>("mostViewed");
  const [chartData, setChartData] = useState<Highcharts.Options>({
    chart: {
      type: "line",
      style: {
        fontSize: "16px",
        color: "#666666",
        fontFamily: "Montserrat, sans-serif",
      },
    },
    title: {
      text: undefined,
    },
    legend: {
      enabled: false,
    },
    series: [
      {
        type: "line",
        name: brandChartTabs[0].label,
        color: brandChartTabs[0].dataColor,
        lineWidth: 0,
      },
    ],
    tooltip: {
      useHTML: true,
      backgroundColor: "transparent",
      borderWidth: 0,
      shadow: false,
    },
    xAxis: {
      type: "linear",
      crosshair: {
        color: "black",
        width: 4,
      },
      categories: [],
      labels: {
        format: "{value:%b %e}",
      },
    },
    credits: {
      enabled: false,
    },
    yAxis: [
      {
        type: "logarithmic",
        title: {
          text: "Sponsored Video Views",
        },
        min: 0,
      },
    ],
    plotOptions: {
      series: {
        zIndex: 100,
        clip: true,
      },
    },
    responsive: {
      rules: [
        {
          condition: {
            maxWidth: 1300, // Breakpoint for small screens
          },
          chartOptions: {
            xAxis: {
              labels: {
                rotation: -45, // Rotate labels for better fit
                style: {
                  fontSize: "10px",
                },
              },
            },
            yAxis: {
              labels: {
                style: {
                  fontSize: "10px",
                },
              },
            },
            legend: {
              enabled: true,
              itemStyle: {
                fontSize: "10px",
              },
            },
          },
        },
      ],
    },
  });
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [modalData, setModalData] = useState<any>(null);

  const updateDateRange = (interval: string) => {
    const now = dayjs();
    let startDate;

    switch (interval) {
      case "daily":
        startDate = now.subtract(30, "days");
        break;
      case "weekly":
        startDate = now.subtract(90, "days");
        break;
      case "monthly":
        startDate = now.subtract(365, "days");
        break;
      default:
        startDate = now.subtract(30, "days");
    }

    const earliestDate = brandInfo?.earliestDataDate
      ? dayjs(brandInfo.earliestDataDate).startOf("day")
      : startDate;

    setDateRange({
      startDate: startDate.isBefore(earliestDate) ? earliestDate : startDate,
      endDate: now,
    });
  };

  const handleSelectChange = (value: string) => {
    const now = dayjs();
    setSelectedValue(value);

    if (value === "earliest" && brandInfo?.earliestDataDate) {
      const earliestDate = dayjs(brandInfo.earliestDataDate).startOf("day");

      setDateRange({
        startDate: earliestDate,
        endDate: now,
      });
    } else {
      const selectedRange = quickDateRanges.find((e) => e.value === value);
      if (selectedRange) {
        const tempStartDate = selectedRange.startDate ?? now;
        const tempEndDate = selectedRange.endDate ?? now;

        const earliestDate = brandInfo?.earliestDataDate
          ? dayjs(brandInfo.earliestDataDate).startOf("day")
          : tempStartDate;

        setDateRange({
          startDate: tempStartDate.isBefore(earliestDate)
            ? earliestDate
            : tempStartDate,
          endDate: tempEndDate,
        });
      }
    }
  };

  const handleRangeChange = (dates: [Dayjs | null, Dayjs | null] | null) => {
    if (!dates || !dates[0] || !dates[1]) {
      console.warn("Invalid dates selected:", dates);
      return;
    }

    let startDate = dayjs(dates[0]).startOf("day");
    let endDate = dayjs(dates[1]).endOf("day");
    // Enforce maximum range of 3 years (365 days)
    const earliestDate = brandInfo?.earliestDataDate
      ? dayjs(brandInfo.earliestDataDate).startOf("day")
      : startDate;

    setDateRange({
      startDate: startDate.isBefore(earliestDate) ? earliestDate : startDate,
      endDate,
    });

    setSelectedValue(""); // Reset quick range
  };

  const handleIntervalChange = (interval: string) => {
    // // Update date range based on the interval
    updateDateRange(interval);
    setSelectedInterval(interval);
    setSelectedValue("");
    if (interval === "daily") {
      // Keep the current tab selections as-is
      setSelectedTabs((prevSelectedTabs) => prevSelectedTabs);
    } else if (interval === "weekly" || interval === "monthly") {
      // Select the last two tabs (2nd and 3rd tabs) and deselect the first tab
      setSelectedTabs([brandChartTabs[1].key, brandChartTabs[2].key]);
    }
  };

  const handleTabClick = (key: number) => {
    setSelectedTabs((prevSelectedTabs) => {
      const updatedTabs = prevSelectedTabs.includes(key)
        ? prevSelectedTabs.filter((tabKey) => tabKey !== key) // Deselect
        : [...prevSelectedTabs, key]; // Select

      return updatedTabs;
    });
  };

  // Fetch graph data on page load and interval change
  useEffect(() => {
    if (brandInfo && selectedTabs.length > 0) {
      setLoadingChart(true);
      getGraphData(
        brandChartTabs.filter((tab) => selectedTabs.includes(tab.key)),
        brandInfo.id,
        dateRange.startDate.valueOf(),
        dateRange.endDate.valueOf(),
        selectedInterval
      );
    }
  }, [brandInfo, selectedTabs, selectedInterval, dateRange]);

  useEffect(() => {
    const fetchPageData = async () => {
      try {
        setLoadBrandStat(true);
        setLoadBrandVideos(true);
        setLoadingChart(true);
        const result = await get<BrandInfo>(
          `gospel/brand/info?slug=${brandId}`
        );
        if (result) {
          setBrandInfo(result);
          const brandStats = await get<BrandStatistics>(
            `/gospel/brand/overview?id=${result.id}`
          );
          if (brandStats) {
            const brandStatisticsArray = [
              {
                category: "Views on Sponsored Videos",
                stats: [
                  {
                    title: "7 Day Views",
                    key: "sevenDayViews",
                    value: formatNumberWithCommas(
                      brandStats.statistics.sevenDayViews
                    ),
                  },
                  {
                    title: "30 Day Views",
                    key: "thirtyDayViews",
                    value: formatNumberWithCommas(
                      brandStats.statistics.thirtyDayViews
                    ),
                  },
                  {
                    title: "90 Day Views",
                    key: "ninetyDayViews",
                    value: formatNumberWithCommas(
                      brandStats.statistics.ninetyDayViews
                    ),
                  },
                ],
              },
              {
                category: "# of Sponsored Videos",
                stats: [
                  {
                    title: "7 Day Videos",
                    key: "sevenDayVideoCount",
                    value: formatNumberWithCommas(
                      brandStats.statistics.sevenDayVideoCount
                    ),
                  },
                  {
                    title: "30 Day Videos",
                    key: "thirtyDayVideoCount",
                    value: formatNumberWithCommas(
                      brandStats.statistics.thirtyDayVideoCount
                    ),
                  },
                  {
                    title: "90 Day Videos",
                    key: "ninetyDayVideoCount",
                    value: formatNumberWithCommas(
                      brandStats.statistics.ninetyDayVideoCount
                    ),
                  },
                ],
              },
            ];

            setBrandStats(brandStatisticsArray);
            setLoadBrandStat(false);
          }

          const brandedVideoResult: any = await get<BrandTrendingVideoInfo>(
            `/gospel/brand/most?id=${result?.id}`
          );
          if (brandedVideoResult) {
            setTrendingVideoInfo(brandedVideoResult);
          }
          setLoadBrandVideos(false);

          getGraphData(
            brandChartTabs
              .filter((e) => selectedTabs.includes(e.key))
              .map((e) => e),
            result?.id ?? 0,
            dateRange.startDate.valueOf(),
            dateRange.endDate.valueOf(),
            selectedInterval
          );
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchPageData();
  }, [brandId]);

  const getGraphData = async (
    sTabs: {
      dataGraph: string;
      label: string;
      dataColor: string;
      keyName: string;
    }[],
    brand_id: number,
    startDate: number,
    endDate: number,
    interval: string
  ) => {
    let series: SeriesOptionsType[] = [];
    let xAxisCategories: string[] = [];
    let graphData: any;
    let scatterData: any[] = []; // Collect scatterplot-specific data

    // Determine the date format dynamically based on the interval or selected value
    let dateFormat: string;
    let xAxisLabelFormat: string;

    if (interval === "monthly") {
      dateFormat = "YYYY-MM";
      xAxisLabelFormat = "MMM YYYY";
    } else if (interval === "weekly") {
      dateFormat = "YYYY-MM-DD"; // Use full date format to ensure uniqueness
      xAxisLabelFormat = "MMM DD"; // Format for X-axis display
    } else if (interval === "daily") {
      dateFormat = "YYYY-MM-DD"; // Full date format for daily data
      xAxisLabelFormat = "MMM DD"; // Format for X-axis display
    } else {
      dateFormat = "YYYY-MM-DD"; // Default format
      xAxisLabelFormat = "MMM DD"; // Default X-axis label format
    }

    try {
      for (let i = 0; i < sTabs.length; i++) {
        if (sTabs[i].dataGraph === "scatterplot") {
          graphData = await get<BrandChartData>(
            `/gospel/brand/stats/${sTabs[i].dataGraph}?brand_id=${brand_id}&start=${startDate}&end=${endDate}`
          );
        } else {
          graphData = await get<BrandChartData>(
            `/gospel/brand/stats/${sTabs[i].dataGraph}?brand_id=${brand_id}&start=${startDate}&end=${endDate}&interval=${interval}`
          );
        }

        if (graphData) {
          const formattedGraphData = graphData.map((e: any) => ({
            ...e,
            formattedDate:
              interval === "weekly"
                ? dayjs.utc(e.date).startOf("week").format("YYYY-MM-DD")
                : interval === "monthly"
                  ? dayjs.utc(e.date).startOf("month").format("YYYY-MM")
                  : dayjs.utc(e.date).format(dateFormat),
          }));

          // Create a full date range based on startDate and endDate
          let fullDateList: string[] = [];
          let current = dayjs.utc(startDate);
          const end = dayjs.utc(endDate);

          if (interval === "weekly") {
            const actualStart = dayjs.utc(startDate);
            const actualEnd = dayjs.utc(endDate);

            current = dayjs.utc(startDate);

            while (current.isSameOrBefore(actualEnd, "day")) {
              const weekStart = current.clone().startOf("week");

              // Skip if weekStart is before actualStart
              if (weekStart.isBefore(actualStart, "day")) {
                current = current.add(1, "day");
                continue;
              }

              // Stop if weekStart is beyond end
              if (weekStart.isAfter(actualEnd, "day")) break;

              const weekStartStr = weekStart.format("YYYY-MM-DD");
              if (!fullDateList.includes(weekStartStr)) {
                fullDateList.push(weekStartStr);
              }

              current = current.add(1, "day");
            }
          } else if (interval === "monthly") {
            current = current.startOf("month");

            while (current.isSameOrBefore(dayjs.utc(end), "month")) {
              fullDateList.push(current.format("YYYY-MM"));
              current = current.add(1, "month");
            }
          } else {
            while (current.isBefore(dayjs.utc(end), "day")) {
              fullDateList.push(current.format("YYYY-MM-DD"));
              current = current.add(1, "day");
            }
          }

          xAxisCategories = fullDateList.map((date) => {
            return dayjs.utc(date, dateFormat).format(xAxisLabelFormat);
          });

          const seriesType =
            sTabs[i].dataGraph === "scatterplot" ? "scatter" : "line";

          if (sTabs[i].dataGraph === "scatterplot") {
            scatterData = formattedGraphData.map((e: any) => {
              const formattedDate = e.formattedDate;
              return {
                x: fullDateList.indexOf(formattedDate),
                y: e[sTabs[i].keyName],
                fullDate: dayjs.utc(e.date).format("YYYY-MM-DD HH:mm:ss"),
                channelName: e.channelTitle,
                color:
                  e[sTabs[i].keyName] < 0
                    ? "rgb(189, 57, 73)"
                    : sTabs[i].dataColor,
                brand: e.brand,
                date: formattedDate,
                gospelPageUrl: e.gospelPageUrl,
                thumbnailUrl: e.thumbnailUrl,
                videoTitle: e.videoTitle,
                videoUrl: e.videoUrl,
                views: e.views,
              };
            });
          }
          series.push({
            type: seriesType,
            name: sTabs[i].label,
            yAxis: i,
            data:
              seriesType === "scatter"
                ? scatterData
                : fullDateList.map((date) => {
                    const match = formattedGraphData.find(
                      (d: any) => d.formattedDate === date
                    );
                    return {
                      x: fullDateList.indexOf(date),
                      y: match ? match[sTabs[i].keyName] : null,
                      fullDate: match
                        ? dayjs.utc(match.date).format("YYYY-MM-DD HH:mm:ss")
                        : dayjs.utc(date).format("YYYY-MM-DD HH:mm:ss"),
                    };
                  }),
            color: sTabs[i].dataColor,
            lineWidth: seriesType === "scatter" ? 0 : chartLineWidth,
            zones: [
              {
                value: 0,
                color: "rgb(189, 57, 73)", // Red for negative
              },
              {
                color: sTabs[i].dataColor, // Default color for positive values
              },
            ],
            marker: {
              fillColor: sTabs[i].dataColor,
              states: {
                hover: {
                  fillColor: sTabs[i].dataColor,
                },
              },
              symbol: "circle",
            },
          } as SeriesLineOptions | SeriesScatterOptions);
        }
      }

      if (series.length > 0 && xAxisCategories.length > 0) {
        setChartData((prev: any) => ({
          ...prev,
          series: series,
          tooltip: {
            useHTML: true,
            backgroundColor: "transparent",
            borderWidth: 0,
            shadow: false,
            formatter: function () {
              const x: number = this.point.x as number; // X-axis value (date)
              const xIndex = this.point.x as number; // X-axis index

              let formattedDate = xAxisCategories[xIndex];
              if (interval === "weekly") {
                const startOfWeek = dayjs(
                  xAxisCategories[xIndex],
                  xAxisLabelFormat
                ).format("MMM DD");
                const endOfWeek = dayjs(
                  xAxisCategories[xIndex],
                  xAxisLabelFormat
                )
                  .add(6, "days")
                  .format("MMM DD");
                formattedDate = `${startOfWeek} - ${endOfWeek}`;
              }

              // If it's the first tab, display the channel name
              if (this.series.type === "scatter") {
                const channelName: string | undefined = (
                  this.point.options as Highcharts.PointOptionsObject
                ).channelName;
                return `
                  <div class="tooltip">
                      ${channelName}
                   
                  </div>
                `;
              }

              // For other tabs (2nd and 3rd), display the existing behavior
              const tooltipContent: string = sTabs
                .map((item, index) => {
                  if (index !== this.series.index) return ""; // Skip tabs that don't match the hovered series

                  const yValue = this.series.chart.series[index].data.find(
                    (point) => point.x === x
                  )?.y;

                  const formattedValue = Highcharts.numberFormat(
                    yValue as number,
                    0,
                    ".",
                    ","
                  );

                  return `
                    <span class="tooltipNumber">
                      <span class="tooltipColor" style="background:${item.dataColor}"></span>
                      <span class="tooltipKey" style="color:${item.dataColor}"> ${item.label}: </span>
                      ${item.dataGraph === "views" ? formatLargeNumberWithDecimal(yValue as number) : formattedValue}
                    </span>
                  `;
                })
                .join("");

              return `
                <div class="tooltip">
                  <span class="tooltipDate">${formattedDate}</span>
                  ${tooltipContent}
                </div>
              `;
            },
          },
          xAxis: {
            ...prev.xAxis,
            categories: xAxisCategories,
            tickInterval: 1,
            type: "category",
            min: 0,
            max: xAxisCategories.length - 1, //  this caps the axis

            tickPositioner: undefined,
            labels: {
              // formatter: function () {
              //   return dayjs(this.value).format(xAxisLabelFormat);
              // },
              rotation: -45,
              style: {
                fontSize: "12px",
                color: "#666666",
                fontFamily: "Montserrat, sans-serif",
                textOverflow: "none",
                whiteSpace: "nowrap",
              },
              y: 35,
            },
            tickWidth: 0.5,
            startOnTick: true, // Start ticks on the first data point
            showFirstLabel: true, // Ensure the first label is shown
            endOnTick: false,
            showLastLabel: true,
            tickmarkPlacement: "on", // Ensure ticks are aligned with labels
          },
          yAxis: series.map((e: SeriesOptionsType, i: number) => {
            return {
              type: e.type === "scatter" && "logarithmic",
              title: {
                text:
                  e.name === "Views on Sponsored Videos" ? e.name : `${e.name}`,
              },
              min: undefined, // Let Highcharts automatically calculate the minimum
              max: undefined, // Let Highcharts automatically calculate the maximum
              startOnTick: true, // Ensure the axis starts at a rounded value
              endOnTick: true, // Ensure the axis ends at a rounded value
              labels: {
                formatter: function () {
                  return formatLargeNumber(this.value as number);
                },
                style: {
                  fontSize: "12px",
                  color: "#666666",
                  fontFamily: "Montserrat, sans-serif",
                  textOverflow: "none",
                  whiteSpace: "nowrap",
                },
              },

              opposite: i % 2 === 0 ? false : true,
            };
          }),

          exporting: {
            enabled: userAccess?.includes("graph_csv_download"),
            buttons: {
              contextButton: {
                align: "right",
                verticalAlign: "top",
                menuItems: [
                  // {
                  //   text: "Download JPEG image",
                  //   onclick: () => {
                  //     if (chartRef.current?.chart) {
                  //       chartRef.current.chart.exportChartLocal(
                  //         {
                  //           type: "image/jpeg",
                  //           filename: `${brandInformation?.name}-YouTube-Graph`,
                  //         },
                  //         {
                  //           title: {
                  //             text: `${brandInformation?.name}-YouTube-Graph`,
                  //             align: "center",
                  //             verticalAlign: "top",
                  //             style: {
                  //               fontSize: "18px",
                  //               fontFamily: "Montserrat, sans-serif",
                  //               color: "#333",
                  //             },
                  //             y: 20, // Add space above the chart area for the title
                  //           },
                  //           chart: {
                  //             spacingTop: 40, // Adjust spacing to fit the title
                  //           },
                  //           plotOptions: {
                  //             series: {
                  //               dataLabels: {
                  //                 enabled: true, // Ensure data labels are shown in export
                  //                 formatter: function () {
                  //                   const value = this.y; // Access the y value of the data point
                  //                   if (value) {
                  //                     if (Math.abs(value) >= 1000000000) {
                  //                       return (
                  //                         (value / 1000000000).toFixed(1) + "B"
                  //                       ); // Format for billions
                  //                     } else if (Math.abs(value) >= 1000000) {
                  //                       return (
                  //                         (value / 1000000).toFixed(1) + "M"
                  //                       ); // Format for millions
                  //                     } else if (Math.abs(value) >= 1000) {
                  //                       return (value / 1000).toFixed(1) + "K"; // Format for thousands
                  //                     } else {
                  //                       return value.toFixed(1); // Show value with 1 decimal for less than 1000
                  //                     }
                  //                   }
                  //                 },
                  //               },
                  //             },
                  //           },
                  //         }
                  //       );
                  //     }
                  //   },
                  // },
                  {
                    text: "Download CSV",
                    onclick: function () {
                      const chart = chartRef?.current?.chart;
                      if (chart) {
                        const csvRows = [
                          ["DateTime", ...chart.series.map((s) => s.name)],
                        ];
                  
                        const dataLength = chart.series[0]?.data.length || 0;
                  
                        for (let i = 0; i < dataLength; i++) {
                          const row: string[] = [];
                  
                          // Try fullDate first, fallback to X-axis categories
                          let fullDate = chart.series[0]?.data[i]?.fullDate;
                          if (!fullDate && chart.xAxis[0].categories) {
                            fullDate = chart.xAxis[0].categories[i];
                          }
                  
                          if (!fullDate) continue;
                  
                          row.push(moment(fullDate).format("YYYY-MM-DD"));
                  
                          chart.series.forEach((series) => {
                            const point = series.data[i];
                            row.push(point?.y?.toString() || "0");
                          });
                  
                          csvRows.push(row);
                        }
                  
                        // Build the CSV content with UTF-8 BOM to make Excel happy
                        const csvContent =
                          "\uFEFF" + // UTF-8 BOM
                          csvRows.map((row) => row.join(",")).join("\n");
                  
                        const blob = new Blob([csvContent], {
                          type: "text/csv;charset=utf-8;",
                        });
                  
                        const url = URL.createObjectURL(blob);
                        const link = document.createElement("a");
                        link.href = url;
                        link.setAttribute("download", "chart.csv");
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                      }
                    },
                  }
                  
                  
                ],

                symbol: "menu",
                theme: {
                  fill: "#ffffff",
                  stroke: "none",
                  states: {
                    hover: {
                      fill: "#f0f0f0",
                    },
                    select: {
                      fill: "#e0e0e0",
                    },
                  },
                },
              },
            },
          },
          plotOptions: {
            series: {
              clip: true, // Ensure that the graph remains inside the chart area
              zIndex: 100,
              allowPointSelect: true,
              point: {
                events: {
                  click: function () {
                    // Check if the series type is scatter
                    if (this.series.type === "scatter") {
                      const {
                        x,
                        channelName,
                        date,
                        gospelPageUrl,
                        thumbnailUrl,
                        videoTitle,
                        videoUrl,
                        views,
                      } = this as Highcharts.PointOptionsObject; // Access the x-coordinate (date)

                      // Find the matching data for the clicked point
                      const clickedData = {
                        channelName,
                        date,
                        gospelPageUrl,
                        thumbnailUrl,
                        videoTitle,
                        videoUrl,
                        views,
                      };

                      setIsModalOpen(true);
                      setModalData(clickedData); // Assuming `videos` is an array
                    }
                  },
                },
              },
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 1300, // Breakpoint for small screens
                },
                chartOptions: {
                  xAxis: {
                    labels: {
                      rotation: -45, // Rotate labels for better fit
                      style: {
                        fontSize: "10px",
                      },
                    },
                  },
                  yAxis: {
                    labels: {
                      style: {
                        fontSize: "10px",
                      },
                    },
                  },
                  legend: {
                    enabled: true,
                    itemStyle: {
                      fontSize: "10px",
                    },
                  },
                },
              },
            ],
          },
        }));
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoadingChart(false);
    }
  };

  const platforms: Platform[] = (brandInfo?.links ?? [])
    .map((url: string) => getPlatform(url))
    .filter((value, index, self) => self.indexOf(value) === index);

  return (
    <div className="overflow-hidden flex flex-col min-h-screen">
      <Header />
      <div className="w-full px-8 py-8 overflow-hidden bg-gospel-black-100 relative mt-[70px] flex justify-center items-center">
        <img
          src={brandInfo?.channelImage}
          className="w-52 h-52 rounded-full overflow-hidden mr-5 md:w-100 md:h-auto"
          alt="title"
        />
        <div className="flex flex-col items-start justify-start gap-3">
          <h1 className="text-2xl leading-tight font-semibold text-white">
            {brandInfo?.name}
          </h1>

          <div className="flex flex-row items-center justify-center">
            <p className="text-15 text-white font-medium">Category:</p>
            <button
              className="text-15 text-white font-medium  cursor-pointer opacity-50 hover:opacity-100 ml-2"
              onClick={() =>
                navigate(`/brand/category/${brandInfo?.categorySlug}`, {
                  state: { categoryName: brandInfo?.categoryName },
                })
              }
            >
              {brandInfo?.categoryName}
            </button>
          </div>

          <div className="gap-3 flex justify-center items-center">
            {platforms.map((platform) => (
              <a
                key={platform}
                href={
                  brandInfo?.links?.find(
                    (url) => getPlatform(url) === platform
                  ) || "#"
                }
                target="_blank"
                rel="noopener noreferrer"
                className="text-gospel-gray-400 opacity-50 hover:opacity-100"
              >
                {platformIcons[platform]}
              </a>
            ))}
          </div>
        </div>
      </div>

      <div className="p-4">
        <div className="bg-white z-50 min-h-400px py-10 px-3 shadow-md">
          <div className="flex flex-wrap justify-center items-center gap-3">
            {brandChartTabs
              .filter((tab, index) => {
                // Show all tabs if the interval is "daily"
                if (selectedInterval === "daily") return true;

                // Show only the last two tabs for other intervals
                return index >= brandChartTabs.length - 2;
              })
              .map((tab) => (
                <div
                  className="w-full sm:w-auto md:w-auto text-center mb-4"
                  key={tab.key}
                >
                  <GospelButton
                    className={`h-[40px] w-full sm:w-auto rounded-md text-sm font-medium transition duration-500 cursor-pointer px-6 ${
                      selectedTabs.includes(tab.key)
                        ? "text-white"
                        : "text-gospel-gray-400 bg-gospel-white-100 hover:text-gospel-gray-300"
                    }`}
                    style={{
                      backgroundColor: selectedTabs.includes(tab.key)
                        ? tab.dataColor
                        : "",
                    }}
                    label={tab.label}
                    onClick={() => handleTabClick(tab.key)}
                  />
                </div>
              ))}
          </div>
          <div className="flex flex-col justify-between md:flex-row">
            <div className="flex flex-wrap">
              {chartInterval.map((interval) => (
                <button
                  key={interval}
                  className={`bg-white flex items-center cursor-pointer text-sm px-4 py-3 transition-colors duration-500 ease-in-out hover:text-gospel-gray-300  font-bold${
                    selectedInterval === interval
                      ? "bg-gospel-gray-300  text-gospel-gray-300"
                      : "bg-white text-gospel-gray-400"
                  }`}
                  onClick={() => handleIntervalChange(interval)}
                  onKeyUp={() => {}}
                >
                  <span
                    className={`${
                      selectedInterval === interval
                        ? "bg-gospel-purple border-gospel-purple"
                        : "bg-white border-gospel-gray-500"
                    } rounded-full inline-block w-4 h-4 mr-1 border-2  hover:text-gray-500 transition-border duration-500 ease-in-out relative`}
                  ></span>
                  {interval.charAt(0).toUpperCase() + interval.slice(1)}
                </button>
              ))}
            </div>
            <div className="mt-4 md:mt-0 flex flex-col md:flex-row items-center gap-4">
              <RangePicker
                className="appearance-none w-60 h-10 px-3 text-sm text-gospel-purple font-medium bg-white border border-gray-300 rounded-md hover:border-gray-400 focus:outline-none focus:border-purple-700"
                defaultValue={[dateRange.startDate, dateRange.endDate]}
                value={[dateRange.startDate, dateRange.endDate]}
                disabledDate={(current) => {
                  // Disable dates after today
                  return current && current > dayjs().endOf("day");
                }}
                onChange={handleRangeChange}
                format="MM/DD/YYYY"
              />

              <div className="relative inline-block text-left">
                <GospelSelectBox
                  options={options}
                  value={selectedValue}
                  onChange={handleSelectChange}
                  containerClass="relative max-w-[450px]"
                  className="w-60 h-10"
                  placeholder="Quick Date Ranges"
                />
              </div>
            </div>
          </div>
          <div
            className={`bg-white z-50 min-h-400px pt-6 pb-10 px-3 shadow-md  relative  ${
              loadingChart ? "opacity-20" : ""
            }`}
          >
            {loadingChart && (
              <div className="absolute inset-0 flex items-center justify-center bg-white bg-opacity-50">
                <CircularProgress
                  className="text-gospel-gray-300 font-bold z-10"
                  size={30}
                />
              </div>
            )}
            <HighchartsReact
              highcharts={Highcharts}
              options={chartData}
              ref={chartRef}
            />
            {isModalOpen && modalData && (
              <ConfigProvider
                theme={{
                  components: {
                    Modal: {
                      titleFontSize: 18,
                      titleColor: "#353b3d",
                      fontFamily: "Montserrat, sans-serif",
                    },
                  },
                }}
              >
                <div className="">
                  <Modal
                    title=""
                    open={isModalOpen}
                    footer={null}
                    closeIcon={
                      <Clear
                        fontSize="small"
                        className="text-white bg-gospel-gray-600 hover:bg-black rounded-full p-0.5 "
                        onClick={() => setIsModalOpen(false)}
                      />
                    }
                    className="w-[300px] absolute inset-0 flex items-center"
                  >
                    <div className="flex flex-col items-start mt-1">
                      <a
                        href={modalData.videoUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <img
                          src={modalData.thumbnailUrl} // Replace with actual thumbnail property
                          alt={modalData.title}
                          className="h-36 w-64"
                        />

                        <h3 className="text-gospel-purple cursor-pointer my-2">
                          {modalData.videoTitle}
                        </h3>
                      </a>

                      <span className="block">
                        <strong>Channel:</strong>
                        <a
                          href={modalData.gospelPageUrl}
                          target="_self"
                          rel="noopener noreferrer"
                          className="text-gospel-purple cursor-pointer ml-1"
                        >
                          {modalData.channelName}
                        </a>
                      </span>
                      <span>
                        <strong>Views:</strong>{" "}
                        {formatNumberWithCommas(modalData.views)}
                      </span>
                      <span>
                        {" "}
                        <strong>Upload Date:</strong>{" "}
                        {dayjs(modalData.date).format("DD MMM YYYY")}
                      </span>
                    </div>
                  </Modal>
                </div>
              </ConfigProvider>
            )}
          </div>
        </div>
        <div>
          {loadBrandStat ? (
            <div className="p-8 flex items-center justify-center bg-white">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : (
            <>
              <div className="flex flex-col bg-white shadow-md justify-between p-4 md:p-8 gap-4 md:gap-8">
                {brandStats && brandStats.length > 0 && (
                  <div className="w-full grid grid-cols-1 md:grid-cols-2 gap-8">
                    {brandStats.map((category: any, index: number) => (
                      <div key={index} className="w-full">
                        {/* Category Header */}
                        <div className="py-5 border-b-2 border-gray-300 w-full text-center">
                          <span className="text-xl font-semibold text-center">
                            {category.category}
                          </span>
                        </div>

                        {/* List of Stats */}
                        {category.stats.map((data: any) => (
                          <div
                            className="w-full py-5 border-b-2 border-gray-300 relative  flex items-center justify-between"
                            key={data.key}
                          >
                            <div>
                              <span className="text-lg font-semibold inline-block relative">
                                {data.title}
                              </span>
                            </div>

                            <div className="flex items-center gap-2">
                              <span className="float-right text-lg font-semibold text-gray-800">
                                {formatNumberWithCommas(data.value)}
                              </span>
                            </div>
                          </div>
                        ))}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </>
          )}
        </div>
      </div>
      {/* {Most recent Videos} */}
      <div className="px-4 mb-12">
        <div className="flex flex-col bg-white shadow-md py-8 items-center">
          {loadBrandVideos ? (
            <div className="p-8 flex items-center justify-center bg-white">
              <CircularProgress
                className="text-gospel-gray-300 font-bold z-10"
                size={30}
              />
            </div>
          ) : (
            <>
              {/* Tabs */}
              <ConfigProvider
                theme={{
                  components: {
                    Tabs: {
                      inkBarColor: "#9D63B0",
                      itemSelectedColor: "#353b3d",
                      itemHoverColor: "#353b3d",
                      itemColor: "#adafaf",
                      titleFontSize: 16,
                      fontFamily: "Montserrat",
                    },
                  },
                }}
              >
                <Tabs
                  defaultActiveKey="mostViewed"
                  onChange={(key) => setActiveTab(key as TrendingVideoKey)}
                  items={[
                    {
                      key: "mostViewed",
                      label: "Most Viewed Sponsored Videos",
                    },
                    {
                      key: "mostRecent",
                      label: "Most Recent Sponsored Videos",
                    },
                  ]}
                />
              </ConfigProvider>
              <div className="overflow-x-auto flex justify-center w-full">
                <TrendingVideoTable
                  data={trendingVideoInfo[activeTab]}
                  showTrendingVideos={showTrendingVideos}
                />
              </div>
              {trendingVideoInfo[activeTab]?.length > 2 && (
                <GospelButton
                  className="bg-white px-2 self-center mt-6 py-2 w-28 rounded-md text-sm leading-tight border border-gray-300 text-gospel-purple transition duration-500 ease-in-out cursor-pointer font-medium hover:text-gray-700 hover:border-gray-400"
                  label={showTrendingVideos ? "Show Less" : "View More"}
                  onClick={() => setShowTrendingVideos(!showTrendingVideos)}
                />
              )}
            </>
          )}
        </div>
      </div>

      {/* {Channel Search} */}
      {brandInfo && <ChannelSearch brandInfo={brandInfo} />}

      {/* {Video Search} */}
      {brandInfo && <VideoSearch brandInfo={brandInfo} />}
      <Footer />
    </div>
  );
}

export default Brand;
